<!-- 多中心研究样本与数 -->
<template>
  <div class="multicenter">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'多中心研究样本与数据管理平台'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="apps-container">
      <div class="banxin">
        <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
        <div class="apps-of-img">
          <div class="apps-text">{{ appsData.text | myTrim }}<div class="know-more" @click="gotoAbout">了解更多</div>
          </div>
          <img class="apps-img" :src="appsData.imgUrl" alt="">
        </div>
      </div>
      <div class="blueBg">
        <div class="blueBg-container">
          <div class="left">
            <div class="zn">想了解更多关于宝晟？</div>
            <div class="en"> Bioshine?</div>
          </div>
          <img src="../../assets/images/cases/content/apps-bg-allow.png" alt="" class="right">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner5.png"),
      business: {
        text: "近几年临床多中心研究越来越受到重视，基于我国众多人口基础上的病源优势，临床多中心研究可获得客观、准确，避免单一研究可能存在的局限性，对指导临床实践有较广泛的意义。其中，在多中心自然人群队列建设中，需要跨中心，跨学科，跨地域收集样本，建立生物样本库，需要采集问卷信息，病历信息、检验检查和实验室实验数据、随访数据信息等。建设数据管理平台支撑多中心自然人群队列建设和研究工作，所涉及的数据来源于多个方面，不仅仅要规范数据信息标准，以便于后续研究工作中的数据清洗、数据整合、数据利用，并且在信息数据安全方面，必须符合《网络安全法》、《数据安全法》、《个人信息保护法》、《健康医疗数据安全指南》和《人类遗传资源管理条例》等一系列法律法规要求。",
        Imgs: []
      },
      appsData: {
        text: "根据中医湿证全国多中心自然人群队列建设的要求，在各分中心内网部署子系统，管 理生物样本、临床信息和随访数据信息。并建立一套完整的数据安全传输机制，包括 国密数据加密机制、HTTPS（SSL）传输协议＋VPN虚拟专用网络访问机制、敏感信 息数据分割传输机制以及去标识化和匿名化技术等，将信息数据传输到数据管理平台 。数据管理平台数据库系统整合各分中心的信息数据和多种途径采集的问卷信息，确 保数据的安全性、完整性和可用性，以支撑整个队列项目的数据存储和管理。数据管 理平台建立严格的权限管理机制，对资源数据库的信息数据进行分级管理，并设置多 级访问权限。在数据管理平台中嵌入临床研究统计分析平台，提供数据治理、基线分 析、高级统计分析、智能 AI分析、智能图表5大功能模块，39个子模块，100余种分 析方法，以满足队列研究的统计分析工作需要。",
        imgUrl: require("@/assets/images/cases/content/apps-multicenter.png")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.apps-container {
  padding: 74px 0 71px;
  position: relative;
  // overflow: hidden;

  // background-color: skyblue;
  .apps-of-img {
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    // background-color: red;

    .apps-text {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 37px;
      letter-spacing: 3px;
      text-align: justify;

      .know-more {
        width: 136px;
        height: 44px;
        margin-top: 36px;
        background: #2A5CE9;
        text-align: center;
        line-height: 44px;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        border-radius: 30px;
        cursor: pointer;
      }
    }

    .apps-img {
      display: block;
      min-width: 549px;
      min-height: 309px;
      margin-left: 85px;
    }
  }



  .blueBg {
    position: absolute;
    bottom: 71px;
    margin: 0 auto;
    padding: 32px 0 33px;
    width: 100%;
    height: 127px;
    background: #2A5CE9;
    box-sizing: border-box;
    z-index: -1;
    // display: flex;
    // align-items: center;

    .blueBg-container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .left {
        .zn {
          height: 33px;
          font-size: 24px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 33px;
        }

        .en {
          height: 25px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 25px;
        }
      }

      .right {
        display: block;
        width: 37px;
        height: 37px;
        margin-left: 144px;
      }
    }
  }
}
</style>